import React from "react"
import {graphql} from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Interweave from "interweave"
import {Avatar} from "@material-ui/core"

// components
import SEO from "../components/Seo/seo"
import Parallax from "../components/Parallax/Parallax"
import GridContainer from "../components/Grid/GridContainer"
import GridItem from "../components/Grid/GridItem"
import homePageStyle from "../assets/jss/pale-legs-style/pages/homepageStyle"
import Card from "../components/Card/Card"
import CardBody from "../components/Card/CardBody"


const useStyles = makeStyles(homePageStyle);
const About=(props)=>{
  const classes = useStyles(props);
  const {bannerImage,bannerTitle,blogAuthor,blogAvatar,content,description,title,filter}=props.data.allSiteData.edges[0].node.content


  return (
    <div style={{marginTop:70}}>
      <SEO
        title={title}
        description={description}
      />
      <Parallax small image={bannerImage} filter={filter}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem container justify={'center'} alignItems={'center'}>
              <h1>{bannerTitle}</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <Card>
          <CardBody>
              <GridContainer justify={'center'}>
                <div style={{display:'flex',flexDirection:'column'}}>
                  <Avatar style={{height:200,width:200}} src={blogAvatar}/>
                  <h3 style={{textAlign:'center'}}>{blogAuthor}</h3>
                </div>

                <div>
                  <Interweave
                    content={content}
                  />
                </div>
              </GridContainer>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}


export const query = graphql`
    {
        allSiteData(filter: {title: {eq: "aboutMe"}}) {
            edges {
                node {
                    id
                    content {
                        bannerImage
                        bannerTitle
                        description
                        filter
                        title
                        content
                        blogAuthor
                        blogAvatar
                    }
                }
            }
        }
    }
`



export default About;