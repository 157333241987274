import { container, title } from "../../pale-legs-styles";


const homePageStyle={
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#eee",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  GridList:{
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  titleBarTitle:{
    font:'bold',
    color:'white'
  },
  gridListTile:{
    margin:10
  },
  gridListTile2:{
    margin:15,
    maxHeight:400,
    borderTopLeftRadius:25,
    borderBottomRightRadius:25,
    boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px',
    transition: 'all .3s ease-in-out',
    '&:hover':{
      boxShadow: "rgba(0, 0, 0, 0.22) 0px 19px 43px",
      transform: 'translate3d(0px, -15px, 0px)'
    }
  }
}

export default homePageStyle;